import App from "./App.vue";
import router from "./router";
import { antComponents } from "./antd";
import { CommonComponents } from "./use-common-components";
import "virtual:svg-icons-register";
import store, { storeKey } from "./store";
import { createInstance } from "/@/root";
import { useDirectives } from "./directives";
import i18n from "@/i18n/index";
import "ant-design-vue/dist/antd.less";
import "/@/styles/index.scss";
// import moment from "moment";

export const app = createInstance(App);
import CoordinateConvert from "/@/vendors/coordtransform.ts";
import "@v3e/vue3-draggable-resizable/dist/Vue3DraggableResizable.css";
window.$gcj2wgs = CoordinateConvert.gcj2WGSExactly;
window.$wgs2gcj = CoordinateConvert.wgs2GCJ;
import "v3-drag-zoom/dist/style.css";
// window.localStorage.removeItem("authDate");
// window.localStorage.removeItem("id");
// moment.locale("zh-cn");
// const setInfo = (data) => {
//   localStorage.setItem("workspaceId", data.workspace_id);
//   localStorage.setItem("workspaceName", data.workspace_name);
//   localStorage.setItem("userName", data.user_name);
//   localStorage.setItem("userId", data.user_id);
//   localStorage.setItem("menu", data.meun);
//   localStorage.setItem("role_name", data.role_name);
// };
// getUserInfo().then((res) => {
//   const { code, data } = res;
//   if (code == 0) {
//     setInfo(data);
//     app.provide("dept", data.dept);

//   }
// });
app.use(i18n);
app.use(store, storeKey);
app.use(router);
app.use(CommonComponents);
app.use(antComponents);
app.use(useDirectives);
app.mount("#demo-app");
