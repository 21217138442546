<template>
  <div class="demo-app">
    <router-view v-if="isShow" />
    <!-- <div class="map-wrapper">
      <GMap/>
    </div> -->
  </div>
</template>

<script lang="ts" setup>
import { getUserInfo } from "@/api/user";
import { EBizCode, ERouterName } from "/@/types";
import EventBus from "/@/event-bus";
import { onMounted, ref, nextTick } from "vue";
import { useConnectWebSocket } from "/@/hooks/use-connect-websocket";
import { app } from "./main";
import { useMyStore } from "/@/store";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const isShow = ref(true);
const store = useMyStore();

const messageHandler = async (payload: any) => {
  if (!payload) {
    return;
  }

  switch (payload.biz_code) {
    case EBizCode.GatewayOsd: {
      store.commit("SET_GATEWAY_INFO", payload.data);
      break;
    }
    case EBizCode.DeviceOsd: {
      console.log("🚀 ~ messageHandler ~ payload.data:", payload.data);

      const { latitude, longitude } = payload.data.host;
      if (latitude && longitude) {
        // 转02
        const [lat, lon] = $wgs2gcj(latitude, longitude);
        payload.data.host.latitude = lat;
        payload.data.host.longitude = lon;
      }

      store.commit("SET_DEVICE_INFO", payload.data);
      break;
    }
    case EBizCode.DockOsd: {
      const { latitude, longitude } = payload.data.host;
      if (latitude && longitude) {
        // 转02
        const [lat, lon] = $wgs2gcj(latitude, longitude);
        payload.data.host.latitude = lat;
        payload.data.host.longitude = lon;
      }
      store.commit("SET_DOCK_INFO", payload.data);
      break;
    }
    case EBizCode.MapElementCreate: {
      store.commit("SET_MAP_ELEMENT_CREATE", payload.data);
      break;
    }
    case EBizCode.MapElementUpdate: {
      store.commit("SET_MAP_ELEMENT_UPDATE", payload.data);
      break;
    }
    case EBizCode.MapElementDelete: {
      store.commit("SET_MAP_ELEMENT_DELETE", payload.data);
      break;
    }
    case EBizCode.DeviceOnline: {
      store.commit("SET_DEVICE_ONLINE", payload.data);
      break;
    }
    case EBizCode.DeviceOffline: {
      store.commit("SET_DEVICE_OFFLINE", payload.data);
      break;
    }
    case EBizCode.FlightTaskProgress:
    case EBizCode.FlightTaskMediaProgress:
    case EBizCode.FlightTaskMediaHighestPriority: {
      EventBus.emit("flightTaskWs", payload);
      break;
    }
    case EBizCode.DeviceHms: {
      store.commit("SET_DEVICE_HMS_INFO", payload.data);
      break;
    }
    case EBizCode.DeviceReboot:
    case EBizCode.DroneOpen:
    case EBizCode.DroneClose:
    case EBizCode.CoverOpen:
    case EBizCode.CoverClose:
    case EBizCode.PutterOpen:
    case EBizCode.PutterClose:
    case EBizCode.ChargeOpen:
    case EBizCode.ChargeClose:
    case EBizCode.DeviceFormat:
    case EBizCode.DroneFormat: {
      store.commit("SET_DEVICES_CMD_EXECUTE_INFO", {
        biz_code: payload.biz_code,
        timestamp: payload.timestamp,
        ...payload.data,
      });
      break;
    }
    case EBizCode.ControlSourceChange:
    case EBizCode.FlyToPointProgress:
    case EBizCode.TakeoffToPointProgress:
    case EBizCode.JoystickInvalidNotify:
    case EBizCode.DrcStatusNotify: {
      EventBus.emit("droneControlWs", payload);
      break;
    }
    case EBizCode.FlightAreasSyncProgress: {
      EventBus.emit("flightAreasSyncProgressWs", payload.data);
      break;
    }
    case EBizCode.FlightAreasDroneLocation: {
      EventBus.emit("flightAreasDroneLocationWs", payload);
      break;
    }
    case EBizCode.FlightAreasUpdate: {
      EventBus.emit("flightAreasUpdateWs", payload.data);
      break;
    }
    default:
      break;
  }
};
// 监听ws 消息
useConnectWebSocket(messageHandler);
const setInfo = (data) => {
  localStorage.setItem("workspaceId", data.workspace_id);
  localStorage.setItem("workspaceName", data.workspace_name);
  localStorage.setItem("userName", data.user_name);
  localStorage.setItem("userId", data.user_id);
  localStorage.setItem("menu", data.meun);
  localStorage.setItem("role_name", data.role_name);
  localStorage.setItem("dept", JSON.stringify(data.dept || []));
};
const reload = () => {
  isShow.value = false;
  nextTick(() => {
    isShow.value = true;
  });
};
app.provide("reload", reload);

onMounted(() => {
  // getUserInfo().then((res) => {
  //   const { code, data } = res;
  //   if (code == 0) {
  //     setInfo(data);
  //     app.provide("dept", data.dept);
  //     isShow.value = true;
  //   }
  // });

  app.provide("dept", [
    {
      id: "e3dea0f5-37f2-4d79-ae58-490af3228069",
      name: t("appName"),
    },
  ]);
});
</script>
<style lang="scss" scoped>
.demo-app {
  width: 100%;
  height: 100%;

  .map-wrapper {
    height: 100%;
    width: 100%;
  }
}
</style>

<style lang="scss">
#demo-app {
  width: 100%;
  height: 100%;
}
</style>
