const SRS_URL = "8.141.7.67";
const LKK_HOST = "192.168.40.194";
const DY_HOST = "192.168.40.186";
const XinJiang_HOST = "www.dockgis.com";
const Frankfurt_HOST = "47.254.154.119";
const JK_HOST = "https://wrj-nw.jchc.cn";

const name: any = import.meta.env.MODE;
const HOST = name == "XinJiang" ? XinJiang_HOST : Frankfurt_HOST;
export const BASE_URL = HOST;
export const CURRENT_CONFIG = {
  // license
  appId: "145901", // You need to go to the development website to apply.
  appKey: "1e03323aa41d14c27b9458373ad7510", // You need to go to the development website to apply.
  appLicense:
    "Z6Rzm1AUpETL+41qjJ57RwQ4FNS7rOdhf5g20WYbpPKQ/0Ndl3yCLFy+F4D7OQBHODXivQYhGHpDtIJNyWVZTshBgJ/XLqS4NwCyJKaf2UGz4c3AMKtmVEVSYU880wYqKCft8ZSBCmOS4v/WDzRUdGWRaOU/9hyHOMxL7VZGjdk=", // You need to go to the development website to apply.

  // http
  // baseURL: "http://192.168.40.188:6789/", // This url must end with "/". Example: 'http://192.168.1.1:6789/'
  // baseURL: JK_HOST, // This url must end with "/". Example: 'http://192.168.1.1:6789/'
  baseURL: `https://${BASE_URL}/`, // This url must end with "/". Example: 'http://192.168.1.1:6789/'
  // websocketURL: `wss://wrj-nw.jchc.cn/api/v1/ws`, // Example: 'ws://192.168.1.1:6789/api/v1/ws'
  websocketURL: `wss://${BASE_URL}/api/v1/ws`, // Example: 'ws://192.168.1.1:6789/api/v1/ws'
  // livestreaming
  // RTMP  Note: This IP is the address of the streaming server. If you want to see livestream on web page, you need to convert the RTMP stream to WebRTC stream.
  rtmpURL: `rtmp://${SRS_URL}/live/`, // Example: 'rtmp://192.168.1.1/live/'
  // GB28181 Note:If you don't know what these parameters mean, you can go to Pilot2 and select the GB28181 page in the cloud platform. Where the parameters same as these parameters.
  gbServerIp: "47.97.228.117",
  gbServerPort: "5060",
  gbServerId: "34020000002000000001",
  gbAgentId: "34020000002000000200",
  gbPassword: "000000",
  gbAgentPort: "7060",
  gbAgentChannel: "34020000002000000200",
  // RTSP
  rtspUserName: "Please enter the username.",
  rtspPassword: "Please enter the password.",
  rtspPort: "8554",
  // Agora
  agoraAPPID: "Please enter the agora app id.",
  agoraToken: "Please enter the agora temporary token.",
  agoraChannel: "Please enter the agora channel.",

  // map
  // You can apply on the AMap website.
  amapKey: "1d6248a0eeb4a507d881f21114ddba91",
};
